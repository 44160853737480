<i18n src="@/locales/pages/privacy.json"></i18n>

<template>
  <section>
    <div class="content">
      <div class="dot-pattern rellax"></div>
      <h1 v-html="$t('privacy.title')"></h1>
      <article id="intro">
        <h2>
          {{ $t('privacy.intro.heading') }}
        </h2>
        <p v-if="typeof $t('privacy.intro.text') == 'string'">
          {{ $t('privacy.intro.text') }}
        </p>
        <p v-else v-for="(text, i) in $t('privacy.intro.text')" :key="i">
          {{ text }}
        </p>
      </article>
      <article id="index">
        <h2>
          {{ $t('privacy.index.heading') }}
        </h2>
        <ul>
          <template v-for="(value, key) in $t('privacy')">
            <li v-if="key != 'title' && key != 'index'" :key="key">
              <router-link :to="`#${key}`" v-html="value.heading"></router-link>
            </li>
          </template>
        </ul>
      </article>
      <article id="representative">
        <h2>
          {{ $t('privacy.representative.heading') }}
        </h2>
        <p class="address">
          {{ $t('privacy.representative.address') }}
        </p>
        <table>
          <tr>
            <th>
              {{ $t('privacy.representative.email.heading') }}
            </th>
            <td>
              <a href="mailto:ich@dennis-adamczyk.de">
                {{ $t('privacy.representative.email.text') }}
              </a>
            </td>
          </tr>
          <tr>
            <th>
              {{ $t('privacy.representative.phone.heading') }}
            </th>
            <td>
              <a href="tel:+4917643811166">
                {{ $t('privacy.representative.phone.text') }}
              </a>
            </td>
          </tr>
          <tr>
            <th>
              {{ $t('privacy.representative.imprint.heading') }}
            </th>
            <td>
              <router-link to="/imprint">
                {{ $t('privacy.representative.imprint.text') }}
              </router-link>
            </td>
          </tr>
        </table>
      </article>
      <article v-for="(block, key) in $t('privacy')" :key="key" :id="key">
        <template v-if="!['intro', 'index', 'representative'].includes(key)">
          <h2 v-html="block.heading"></h2>

          <p v-if="typeof block.text == 'string'" v-html="block.text"></p>
          <article
            v-else-if="block.text && block.text.heading && block.text.text"
          >
            <h3>
              {{ block.text.heading }}
            </h3>
            <p
              v-if="typeof block.text.text == 'string'"
              v-html="block.text.text"
            ></p>
            <template
              v-else-if="Array.isArray(block.text.text)"
              v-for="(value2, j) in block.text"
            >
              <p
                v-if="typeof value2 == 'string'"
                v-html="value2"
                :key="`p${j}`"
              ></p>
              <ul v-else :key="`u${j}`">
                <li v-for="(text, k) in value" :key="k" v-html="text"></li>
              </ul>
            </template>
          </article>
          <template
            v-else-if="typeof block.text == 'object'"
            v-for="(value, i) in block.text"
          >
            <p v-if="typeof value == 'string'" v-html="value" :key="i"></p>
            <article
              v-else-if="value.heading && (value.text || value.list)"
              :key="`a${i}`"
            >
              <h3>
                {{ value.heading }}
              </h3>
              <p v-if="typeof value.text == 'string'" v-html="value.text"></p>
              <template
                v-else-if="typeof value.text == 'object'"
                v-for="(value2, j) in value.text"
              >
                <p
                  v-if="typeof value2 == 'string'"
                  v-html="value2"
                  :key="j"
                ></p>
              </template>
              <ul v-if="value.list">
                <li v-for="(text, k) in value.list" :key="k" v-html="text"></li>
              </ul>
            </article>
            <ul v-else :key="`u${i}`">
              <li v-for="(text, j) in value" :key="j" v-html="text"></li>
            </ul>
          </template>
          <ul v-if="block.list">
            <li v-for="(text, i) in block.list" :key="i" v-html="text"></li>
          </ul>
        </template>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Privacy',
  metaInfo() {
    return {
      title: 'Datenschutz ◂ Dennis Adamczyk',
      meta: [
        {
          name: 'description',
          content:
            'Datenschutzerklärung – Dennis Adamczyk • Web Developer & UI/UX Designer – Privatsphäre ist mir wichtig. Deswegen informiere ich, wie ich deine Daten schütze.',
        },
        {
          name: 'keywords',
          content:
            'dennis adamczyk, datenschutz, datenschutzerklärung, rechtliches, privatsphäre',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
section {
  overflow: hidden;
  padding: 128px 0 96px 0;

  @media all and ($mobile) {
    padding: 96px 0 64px 0;
  }

  .content {
    display: block;
    max-width: $max-view-width;
    margin: 0 auto;
    padding: 0 24px;
    position: relative;

    .dot-pattern {
      display: block;
      position: absolute;
      top: 0;
      right: 0;

      &::before {
        content: '';
        display: block;
        width: 260px;
        height: 120px;
        transform: translate(60%, -40%);
        z-index: 0;

        @include circle-pattern(0.2);

        @media all and ($mobile) {
          transform: translate(80%, -75%);
        }
      }
    }

    h1 {
      font-size: 3.375rem;
      font-weight: 700;
      text-align: left;
      color: $foreground;
      margin: 0 0 48px 0;

      @media all and ($mobile) {
        font-size: 3rem;
        margin: 0 0 32px 0;
      }
    }

    p {
      white-space: pre-line;
      line-height: normal;
    }

    article {
      width: 100%;
      height: auto;
      margin: 32px 0 48px 0;

      h2 {
        font-size: 2rem;
        font-weight: 700;
        color: $foreground;
        text-align: left;
        margin: 0 0 48px 0;

        @media all and ($mobile) {
          font-size: 2rem;
        }
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 700;
        color: $foreground;
        text-align: left;
        margin: 0 0 32px 0;
      }

      p {
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 400;
        color: $text-color;
        margin: 0 0 24px 0;
      }

      & ::v-deep a {
        text-decoration: none;
        color: $primary;
        outline: none;
        word-break: break-word;

        &:hover {
          text-decoration: underline;
        }
      }

      & ::v-deep strong {
        font-weight: 600;
        color: $foreground;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0 0 24px 0;

        li {
          padding-left: 18px;
          line-height: normal;
          position: relative;
          margin-bottom: 4px;

          &::before {
            content: '– ';
            position: absolute;
            left: 0;
          }
        }
      }
    }

    #representative {
      table {
        border-collapse: seperate;
        border-spacing: 0;

        tr {
          th {
            font-weight: 600;
            line-height: normal;
            text-align: left;
            vertical-align: baseline;
            padding-right: 16px;
          }

          td {
            line-height: normal;

            a {
              text-decoration: none;
              color: $primary;
              outline: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}
</style>
